import type { FC } from 'react';
import React, { memo } from 'react';
import { defineMessages } from 'react-intl-next';

import { useAnalyticsEvents } from '@atlaskit/analytics-next';

import { buildCreationLink } from '@confluence/create-blank-fabric-page';
import { Attribution, withTransparentErrorBoundary } from '@confluence/error-boundary';
import { START_TOUCH } from '@confluence/navdex';
import { CREATE_PAGETREE_PAGE_BUTTON_CLICK } from '@confluence/load-edit-page/entry-points/constants';
import { CreateContentButtonPreloader } from '@confluence/edit-button/entry-points/preloaders';

import { CreateButtonBase } from './CreateButtonBase';
import { canCreatePage } from './helpers';

const i18n = defineMessages({
	label: {
		id: 'contextual-create.create-page.label',
		description: 'Label for button that creates pages in the current space',
		defaultMessage: 'Create a page',
	},
});

const CREATE_PAGE_EXPERIENCE_FLOW = 'spaceLevelContextualCreate';

export type Props = {
	spaceKey: string;
};

const CreatePageButtonComponent: FC<Props> = memo(({ spaceKey }) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const fireCreationAnalytics = ({ spaceId }) => {
		createAnalyticsEvent({
			type: 'sendUIEvent',
			data: {
				action: 'clicked',
				actionSubject: 'contextualCreateButton',
				actionSubjectId: 'pagesHeader',
				source: 'containerNavigation',
				containerType: 'space',
				containerId: spaceId,
				attributes: {
					navdexPointType: START_TOUCH,
				},
			},
		}).fire();
	};

	const getButtonHref = (): string | undefined => {
		// SSR doesnt support link inside a link, and this button is inside the `pages` link (can be removed once that HTML structure is updated)
		//  pageTreeItem contextual create isnt rendered in SSR and other contextual create buttons are not nested in a link. so only this button is an issue.
		//  onClick doesnt work in SSR either (HTML only), so we arent losing any functionality by not having a link or onClick before SPA takes over
		// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
		if (!process.env.REACT_SSR) {
			return buildCreationLink({
				contentType: 'page',
				source: CREATE_PAGE_EXPERIENCE_FLOW,
				spaceKey,
			});
		}
		return undefined;
	};

	return (
		<CreateContentButtonPreloader editSource={CREATE_PAGETREE_PAGE_BUTTON_CLICK}>
			<CreateButtonBase
				canCreateContent={canCreatePage}
				onCreateContent={fireCreationAnalytics}
				href={getButtonHref()}
				labelI18n={i18n.label}
				spaceKey={spaceKey}
				tooltipPosition="bottom"
				pagesSideNavButtonStyleOverride
				testId="contextual-create-page-button"
			/>
		</CreateContentButtonPreloader>
	);
});

export const CreatePageButton: FC<Props> = withTransparentErrorBoundary({
	attribution: Attribution.TAILORED_EXPERIENCES,
})(CreatePageButtonComponent);
